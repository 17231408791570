@import './input-select.scss';

.manager {
    &Map {
        &Item {
            cursor: pointer;

            &Explore {
                visibility: hidden;
                color: #ccc;
            }

            &:hover {
                background-color: #fafafa;
                & span {
                    visibility: visible;
                }
            }
        }
    }
}

.weverapp-select-community div[class*="singleValue"] {
    color: white;
}