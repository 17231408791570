.crud-list {
    .form-group {
        margin-bottom: 1rem;

        label {
            margin-bottom: 0;
        }
    }

    table {
        text-align: center;

        td, th {
            vertical-align: middle;

            .custom-control {
                padding-left: 0;
                min-height: auto;

                .custom-control-label {
                    position: relative;

                    &::before, &::after {
                        left: 0;
                        top: -0.75rem;
                        width: 1rem;
                        height: 1rem;
                    }
                }
            }
        }
    }

    .card {
        .input-per-page {
            min-width: 110px;
        }

        .list-loader {
            position: absolute;
            top: -8px;
            width: 100%;
        }
    }
}
