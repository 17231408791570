.tab-sheet-action {
  color: #D73755 !important;
  background: #ffffff;
  font-weight: bold;
}

.tab-sheet-action.active {
  color: white !important;
  background-color: #00AAD7 !important;
}

.text-blue-wever {
  color: #00AAD7;
  font-weight: bold;
  font-size: 20px;
}

.card-img-wever {
  height: auto;
  width: auto;
  max-width: 100px;
  max-height: 200px;
  //box-shadow: 0 4px 8px 0 #e0e0e0;
  border: #aba7a7;
  margin-right: 10px;
}

.card-img-wever:hover {
  cursor: pointer;
}

.weverCardUsefulMedia {
  display: flex;
  text-align: center;
}

#usefulMediaCard {
  text-align: center;
  justify-content: center;
  border: #888787;
  border-radius: 20%;
  box-shadow: 0 auto 8px 0 #af3535;
  height: auto;
  width: auto;
  justify-content: space-between;
}

embed {
  cursor: pointer;
}