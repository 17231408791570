.accordion-item.wever {
    border: 0;
    margin-top: 1.5em;

    .accordion-header, .accordion-header button {
        background-color: transparent!important;
        box-shadow: none;
    }

    .accordion-header {
        border-bottom: 1px solid #ccc;
    }

    .accordion-collapse {
        // border: 1px solid #ccc;
    }
}