.discussion {
  &Self {
    border-radius: 10px;
    max-width: 50%;
    background-color: #00AAD7;
    color: white;
    float: right;
    clear: both;
    margin: 0;
  }

  &Other {
    border-radius: 10px;
    max-width: 50%;
    background-color: #F1F1F5;
    color: black;
    float: left;
    clear: both;
  }

  &Left {
    float: left;
    clear: both;
    margin-top: 15px;
    display: inline-block;
    padding: 10px 0;

   /* p {
      display: inline-block;
      align-items: center;
      //letter-spacing: 2px;
    }*/

    b, span {
      margin-right: 5px;
    }
  }

  &Right {
    float: right;
    clear: both;
    margin-top: 15px;
    display: inline-block;
    padding: 10px 0;

    /*p {
      display: inline-block;
      align-items: center;
      //letter-spacing: 2px;
    }*/

    b, span {
      margin-right: 5px;
    }
  }

  &Category {
    display: inline-block;
    text-align: center;
    background-color: #D73755;
    height: 1.6em;
    line-height: 2.0em;
    color: white;
    border-radius: 10px;
    vertical-align: middle;
    justify-content: center;
    border-spacing: 5px;
    margin-right: 10px;

    p {
      display: table-cell;
      align-items: center;
      font-size: 11px;
    }
  }

  &ProfilePicture {
    border-radius: 50%;
    background-color: #D73755;
    height: 3.0em;
    line-height: 3.0em;
    width: 3.00em;
    display: inline-block;
    vertical-align: middle;
    justify-content: center;
    margin-right: 15px;
    margin-left: 10px;

    p {
      color: white;
      text-align: center;
      align-items: center;
      font-size: 17px;
      padding-top: 10px;
    }
  }
}