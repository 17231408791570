.weverapp-select-community {
    .autocomplete-async {
        min-width: 200px;

        div[class$="control"]{
            background-color: transparent;
            border: none;
            color: black;
        }

        #react-select-2-input {
            color: black;
        }
    }
}