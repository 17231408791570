.navigation.navbar-nav {
    .nav-link {
        cursor: pointer;
        user-select: none;
    }

    .collapse, .collapsing {
        .nav-link {
            padding: .3rem;
            padding-left: 4rem;
            font-size: .8rem;

            i {
                font-size: .8rem;
            }
        }
    }
}
