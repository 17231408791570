.card {
    &.loading,
    .card-body.loading,
    .card-header.loading,
    .card-footer.loading {
        opacity: .5;
        pointer-events: none;
    }

    .card-body {
        button.close-card-right {
            position: absolute;
            top: -10px;
            right: -10px;
        }

        button.close-card-left {
            position: absolute;
            top: -10px;
            left: -10px;

            &.block {
                top: -1px;
                height: calc(100% + 2px);
                padding: 0 2px;
                background-color: var(--lighter);
                color: var(--gray);
                box-shadow: none;
                border-radius: 0;
                border-bottom-left-radius: 0.375rem;
                border-top-left-radius: 0.375rem;
                border: none;
                transition: background-color .3s, color .3s;

                &:hover {
                    top: 0;
                    height: calc(100% + 1px);
                    background-color: var(--red);
                    color: var(--white);
                    border: none;
                }
            }
        }
    }

    &.appDocAction {
        background-color: #fafafa;
        border: 1px solid #ccc;
        border-bottom: 1px solid #ccc!important;
        border-top: 1px solid #ccc!important;
    }
}
